<template>
  <main class="page" id="servers">
    <servers-list :servers="servers" @create="createActive = true" @edit="editActive = true" @del="del"></servers-list>
    <b-modal :active.sync="createActive">
      <servers-create @cancel="createActive = false" @create="create"></servers-create>
    </b-modal>
    <b-modal :active.sync="editActive">
      <servers-edit @cancel="editActive = false" @edit="edit" :server="server"></servers-edit>
    </b-modal>

  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import serversList from './t-servers/t-servers-list'
import serversCreate from './t-servers/t-servers-create'
import serversEdit from './t-servers/t-servers-edit'
export default {
  name: 'servers',
  data () {
    return {
      createActive: false,
      editActive: false
    }
  },
  computed: {
    ...mapGetters('serverStore', [
      'servers',
      'server'
    ])
  },
  beforeMount () {
    this.index(this)
  },
  methods: {
    ...mapActions('serverStore', [
      'index',
      'store',
      'update',
      'destroy'
    ]),
    create (data) {
      this.createActive = false
      this.store([this, data])
    },
    edit (data) {
      this.editActive = false
      this.update([this, data])
    },
    del () {
      this.$buefy.dialog.confirm({
        title: 'Remover o servior?',
        message: `${this.server.name} com ${this.server.clients_count} clientes?`,
        hasIcon: true,
        icon: 'database',
        type: 'is-danger',
        onConfirm: () => {
          this.destroy(this).then(() => {

          }).catch(err => {
            console.log(err)
          })
        }
      })
    }

  },
  components: {
    serversList,
    serversCreate,
    serversEdit
    // serversDetails,
  }
}
</script>
