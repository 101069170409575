<template>
  <form @submit.prevent="$emit('create', { s: server })" id="serversCreate">
    <header>
      <h3>Cadastrar servidor</h3>
    </header>
    <div class="content grid">
      <b-field label="Nome" id="name">
        <b-input placeholder="Nome do servidor" v-model="server.name" required></b-input>
      </b-field>
      <b-field label="Versão" id="version">
        <b-input placeholder="Versão no servidor" v-model="server.version"></b-input>
      </b-field>
      <button class="__error" id="cancel" @click="$emit('cancel')">Cancelar</button>
      <button class="__success" id="confirm" type="submit">Salvar</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'serversCreate',
  data () {
    return {
      server: {
        name: null,
        version: null
      }
    }
  },
  beforeMount () {
  }
}
</script>
