<template>
  <section class="widget" id="serversList">
    <header class="grid">
      <h3>Servidores</h3>
      <span></span>
      <!-- <b&#45;field> -->
      <!--   <b&#45;input placeholder="Buscar por cliente" v&#45;model="slug"></b&#45;input> -->
      <!-- </b&#45;field> -->
      <b-button @click="$emit('del')" class="is-danger" size="is-large" icon-left="trash"></b-button>
      <b-button @click="$emit('edit')" class="__primary" size="is-large" icon-left="pen"></b-button>
      <b-button @click="$emit('create')" class="__highlight" size="is-large" icon-left="plus"></b-button>
    </header>
    <div class="content">
      <b-table
        :data="servers"
        :paginated="true"
        :per-page="10"
        :selected.sync="server">
        <template slot-scope="props">
          <b-table-column field="name" label="Nome">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="version" label="Versão">
            {{ props.row.version }}
          </b-table-column>
          <b-table-column field="version" label="Quantidade de Clientes">
            {{ props.row.clients_count }}
          </b-table-column>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
export default {
  name: 'serversList',
  props: {
    servers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    server: {
      get () {
        return this.$store.getters['serverStore/server']
      },
      set (newVal) {
        this.$store.dispatch('serverStore/setServerIndex', newVal)
      }
    }
  },
  methods: {
  }
}
</script>
