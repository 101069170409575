<template>
  <section class="widget" id="connectionsList">
    <header class="grid">
      <h3>Conexões Remotas</h3>
      <span></span>
      <!-- <pre v&#45;highlightjs><code class="javascript">const s = new Date().toString()</code></pre> -->
      <!-- <span></span> -->
      <b-button @click="helperOpen = true" class="is-warning" size="is-large" icon-left="question"></b-button>
      <b-button @click="$emit('create')" class="__highlight" size="is-large" icon-left="plus"></b-button>
    </header>
    <div class="content">
     <b-table
        :data="connections"
        :paginated="true"
        :per-page="10"
        :row-class="(row, index) => parseWarning(row, index)"
        :selected.sync="connection">
        <template slot-scope="props">
          <b-table-column field="login" label="Login">
            {{ props.row.login }}
          </b-table-column>
          <b-table-column field="ip" label="IP de origem">
            {{ props.row.ip }}
          </b-table-column>
          <b-table-column field="login" label="Banco de dados">
            {{ props.row.database.name }}
          </b-table-column>
          <b-table-column field="login" label="Desenvolvedor">
            {{ props.row.developer.name }}
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
              <b-icon
                icon="sad-tear"
                size="is-large">
              </b-icon>
              </p>
              <p>Nenhuma conexão cadastrada.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="helperOpen">
      <vue-code-highlight v-if="connection">
        mysql -u {{ connection.login }} -h backup.techmobil.com.br -D {{ connection.database.name }} -p
      </vue-code-highlight>
    </b-modal>
  </section>
</template>

<script>
import { component as VueCodeHighlight } from 'vue-code-highlight'
import 'vue-code-highlight/themes/duotone-sea.css'
// import 'vue-code-highlight/themes/window.css'
export default {
  name: 'connectionsList',
  props: {
    connections: {
      type: Array,
      default: () => [
        {
          login: 'thiago-{timestamp}',
          ip: '177.235.203.40',
          active: 1,
          database: {
            name: 'lap-{timestamp}',
            client: {
              slug: 'lap'
            }
          },
          developer: {
            name: 'Thiago',
            user: {
              email: 'thiago@sisvida.com.br'
            }
          }
        }
      ]
    }
  },
  data () {
    return {
      helperOpen: false
    }
  },
  computed: {
    connection: {
      get () {
        return this.$store.getters['connectionStore/connection']
      },
      set (newVal) {
        this.$store.dispatch('connectionStore/setDeveloperIndex', newVal)
      }
    }
  },
  methods: {
    parseWarning (row, index) {
      return ''
      // if (!row.user.active) {
      //   return 'is-disabled'
      // }
    }
  },
  components: {
    VueCodeHighlight
  }
}
</script>
