<template>
  <section class="widget" id="developersList">
    <header class="grid">
      <h3>Usuários dos clientes</h3>
      <span></span>
      <b-button @click="$emit('create')" class="__highlight" size="is-large" icon-left="plus"></b-button>
    </header>
    <div class="content">
      <b-table
        :data="clientusers"
        :paginated="true"
        :per-page="10"
        :row-class="(row, index) => parseWarning(row, index)"
        :selected.sync="clientuser">
        <template slot-scope="props">
          <b-table-column field="slug" label="Nome">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="user.email" label="Email">
            {{ props.row.user.email }}
          </b-table-column>
          <b-table-column field="clients" label="Cliente(s)">
            {{ parseClients(props.row.clients) }}
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
              <b-icon
                icon="sad-tear"
                size="is-large">
              </b-icon>
              </p>
              <p>Nenhum usuário cliente cadastrado.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
export default {
  name: 'clientusersList',
  props: {
    clientusers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {

    }
  },
  computed: {
    clientuser: {
      get () {
        return this.$store.getters['clientuserStore/clientuser']
      },
      set (newVal) {
        this.$store.dispatch('clientuserStore/setClientuserIndex', newVal)
      }
    }
  },
  methods: {
    parseWarning (row, index) {
      if (!row.user.active) {
        return 'is-disabled'
      }
    },
    parseClients (clients) {
      const slugs = clients.reduce((prev, cur) => prev + ' ' + cur.slug, '')
      return slugs
    }
  }
}
</script>
