<template>
  <form class="" id="developersEdit" @submit.prevent="$emit('edit', { developer: dev })">
    <header>
      <h3>{{ developer.name }}</h3>
    </header>
    <div class="content grid">
      <b-field label="Nome" id="name">
        <b-input placeholder="Nome do desenvolvedor" name="name" v-model="dev.name" required></b-input>
      </b-field>
      <b-field label="Ativo" id="active">
          <b-checkbox v-model="dev.user.active" :true-value="1" :false-value="0"></b-checkbox>
      </b-field>
      <b-field label="Email" id="email">
        <b-input placeholder="Email" v-model="dev.user.email" disabled></b-input>
      </b-field>
      <!-- <b&#45;field label="Senha antiga" id="old&#45;password"> -->
      <!--   <b&#45;input type="password" placeholder="Senha" v&#45;model="password" password&#45;reveal></b&#45;input> -->
      <!-- </b&#45;field> -->
      <!-- <button class="__success" type="button" id="check" @click="check">Verificar</button> -->
      <b-field label="Nova Senha" id="password">
        <b-input type="password" placeholder="Senha" v-model="dev.user.password" password-reveal></b-input>
      </b-field>
      <b-field :type="confirmationType.type" :message="confirmationType.message" label="Confirme a senha" id="passwordConfirmation">
        <b-input type="password" placeholder="Senha" v-model="dev.user.passwordConfirmation" password-reveal :required="dev.user.password && dev.user.password.length > 0"></b-input>
      </b-field>
      <button class="__error" id="cancel" type="button" @click="$emit('cancel')">Cancelar</button>
      <button class="__success" id="confirm" type="submit" :disabled="confirmationType.type === 'is-danger'">Atualizar</button>
    </div>
  </form>
</template>

<script>
import { client } from '@/config/index'
export default {
  name: 'developersEdit',
  props: ['developer'],
  data () {
    return {
      password: null,
      confirmed: false,
      dev: {
        id: null,
        name: null,
        user: {
          active: null,
          email: null,
          password: null,
          passwordConfirmation: null
        }
      }
    }
  },
  beforeMount () {
    this.dev.id = this.developer.id
    this.dev.name = this.deepCopy(this.developer.name)
    this.dev.user.email = this.deepCopy(this.developer.user.email)
    this.dev.user.active = this.deepCopy(this.developer.user.active)
  },
  computed: {
    confirmationType () {
      if (this.dev.user.password) {
        if (this.dev.user.password === this.dev.user.passwordConfirmation) {
          return { type: 'is-success' }
        } else {
          return { type: 'is-danger', message: 'As senhas devem ser iguais' }
        }
      }
      return { type: '', message: '' }
    }
  },
  methods: {
    deepCopy (attr) {
      return JSON.parse(JSON.stringify(attr))
    },
    check () {
      let data = {
        username: this.dev.user.email,
        password: this.password
      }
      Object.assign(data, client(this.$store.getters.conn), { grant_type: 'password', scope: '' })
      this.$http.post(this.$api({
        target: 'oauth/token', // the url /api/oauth/tokens
        secure: false // ignore version
      }), data).then(response => {
        if (response.status === 200) {
          this.confirmed = true
        } else {
          this.password = null
        }
      })
    }
  }
}
</script>
