<template>
  <form class="" id="connectionsCreate" @submit.prevent="$emit('create', { conn: connection })">
    <header>
      <h3>Criar uma nova conexão</h3>
    </header>
    <div class="content grid">
      <div id="database">
        <b-field label="Banco de Dados">
          <b-select placeholder="Selecione um banco de dados" v-model="connection.databaseId" required>
            <option v-for="(database, index) in databases" :value="database.id" :key="index">{{ database.name }}</option>
          </b-select>
        </b-field>
      </div>
      <div id="developer">
        <b-field label="Desenvolvedor">
          <b-select placeholder="Selecione um desenvolvedor" v-model="connection.developerId" required>
            <option v-for="(developer, index) in developers" :value="developer.id" :key="index">{{ developer.name }}</option>
          </b-select>
        </b-field>
      </div>
      <div id="ip">
        <b-field label="IP">
          <b-input placeholder="127.0.0.1" pattern="(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)_*(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)_*){3}" v-model="connection.ip" required></b-input>
        </b-field>
      </div>
      <div id="password">
        <b-field label="Senha">
          <b-input type="password" placeholder="Senha" v-model="connection.password" password-reveal required></b-input>
        </b-field>
      </div>
      <div id="passwordConfirmation">
        <b-field :type="confirmationType.type" :message="confirmationType.message" label="Confirme a senha" id="">
          <b-input type="password" placeholder="Senha" v-model="connection.passwordConfirmation" password-reveal required></b-input>
        </b-field>
      </div>
      <button class="__error" id="cancel" @click="$emit('cancel')">Cancelar</button>
      <button class="__success" id="confirm" type="submit">Salvar</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'connectionsCreate',
  data () {
    return {
      validRecords: [],
      connection: {
        databaseId: null,
        developerId: null,
        ip: null,
        password: null,
        passwordConfirmation: null
      }
    }
  },
  watch: {
  },
  computed: {
    databases () {
      return this.$store.getters['databaseStore/databases']
    },
    developers () {
      return this.$store.getters['developerStore/developers']
    },
    confirmationType () {
      return this.connection.password ? (this.connection.password === this.connection.passwordConfirmation ? { type: 'is-success' } : { type: 'is-danger', message: 'As senhas devem ser iguais' }) : { type: 'is-danger', message: 'Preencha a senha' }
    }
  },
  mounted () {
    this.$http.get('https://api.ipify.org/?format=json').then(response => {
      this.connection.ip = response.data.ip
    })
  },
  methods: {
  }
}
</script>
