<template>
  <section class="widget" id="clientuserDetails" v-if="clientuser">
    <header class="grid">
      <h3>{{ clientuser.name }}</h3>
      <span></span>
      <span></span>
      <!-- <b&#45;field> -->
      <!-- </b&#45;field> -->
      <b-button @click="$emit('edit')" class="__primary" size="is-large" icon-left="pen"></b-button>
    </header>
    <div class="content">
      <!-- <b&#45;table -->
      <!--   :data="client.records" -->
      <!--   :paginated="true" -->
      <!--   :per&#45;page="10" -->
      <!--   :selected.sync="client"> -->
      <!--   <template slot&#45;scope="props"> -->
      <!--     <b&#45;table&#45;column field="record_name" label="Ultimo Backup"> -->
      <!--       {{ props.row.record_name }} -->
      <!--     </b&#45;table&#45;column> -->
      <!--     <b&#45;table&#45;column field="created_at" label="Data"> -->
      <!--       {{ parseDate(props.row.date) }} -->
      <!--     </b&#45;table&#45;column> -->
      <!--     <b&#45;table&#45;column field="created_at" label="Status"> -->
      <!--       <v&#45;progress :tests="props.row.tests"></v&#45;progress> -->
      <!--     </b&#45;table&#45;column> -->
      <!--   </template> -->
      <!-- </b&#45;table> -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import dates from '../../../../mixins/dates'
export default {
  name: 'clientsDetails',
  mixins: [dates],
  computed: {
    ...mapGetters('clientuserStore', ['clientuser'])
  }
}
</script>
