<template>
  <main class="page" id="databases">
    <databases-list :databases="databases" @del="del" @create="createActive = true"></databases-list>
    <!-- <databases&#45;details @edit="editActive = true"></databases&#45;details> -->
    <b-modal :active.sync="createActive">
      <databases-create @cancel="createActive = false" @create="create"></databases-create>
    </b-modal>
    <!-- <b&#45;modal :active.sync="editActive"> -->
    <!--   <databases&#45;edit @edit="edit" @cancel="editActive = false" :developer="developer"></databases&#45;edit> -->
    <!-- </b&#45;modal> -->
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import databasesList from './t-databases/t-databases-list'
// import databasesDetails from './databases/databases-details'
import databasesCreate from './t-databases/t-databases-create.vue'
// import databasesEdit from './databases/databases-edit'
export default {
  name: 'databases',
  data () {
    return {
      createActive: false,
      editActive: false,
      loading: {
        active: false,
        color: '#fff'
      }
    }
  },
  computed: {
    ...mapGetters('databaseStore', [
      'database',
      'databases'
    ])
  },
  beforeMount () {
    this.index(this)
  },
  mounted () {
  },
  methods: {
    ...mapActions('databaseStore', [
      'index',
      'store',
      'destroy'
    ]),
    create (database) {
      this.createActive = false
      this.store([this, database]).then(status => {
      }).catch(err => {
        console.log(err)
      })
      // this.createActive = false
    },
    del () {
      this.$buefy.dialog.confirm({
        title: 'Remover Banco de Dados',
        message: `${this.database.name}`,
        hasIcon: true,
        icon: 'database',
        type: 'is-danger',
        onConfirm: () => {
          this.destroy(this).then(() => {
            this.$store.dispatch('connectionStore/index', this)
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    load (color = null) {
      if (color) this.loading.color = color
      setTimeout(() => {
        this.loading.active = !this.loading.active
      }, 1000)
    }
  },
  components: {
    databasesList,
    databasesCreate
    // databasesDetails,
    // databasesEdit
  }
}
</script>
