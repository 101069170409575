<template>
  <main class="page" id="clientusers">
    <clientusers-list :clientusers="clientusers" @create="createActive = true"></clientusers-list>
    <clientusers-details @edit="editActive = true"></clientusers-details>
    <b-modal :active.sync="createActive">
      <clientusers-create @cancel="createActive = false" @create="create"></clientusers-create>
    </b-modal>
    <b-modal :active.sync="editActive">
      <clientusers-edit @edit="edit" @cancel="editActive = false" :clientuser="clientuser"></clientusers-edit>
    </b-modal>
  </main>
</template>

<script>
import clientusersList from './t-clientusers/t-clientusers-list'
import clientusersDetails from './t-clientusers/t-clientusers-details'
import clientusersCreate from './t-clientusers/t-clientusers-create.vue'
import clientusersEdit from './t-clientusers/t-clientusers-edit'
import { mapGetters, mapActions } from 'vuex'

/**
 * Client users
 * @displayName Client users Tab
 */

export default {
  name: 'clientusers',
  data () {
    return {
      createActive: false,
      editActive: false
    }
  },
  computed: {
    ...mapGetters('clientuserStore', [
      'clientusers',
      'clientuser'
    ])
  },
  beforeMount () {
    this.index(this)
  },
  methods: {
    ...mapActions('clientuserStore', [
      'index',
      'store',
      'update'
    ]),
    create (data) {
      this.createActive = false
      this.store([this, data])
    },
    edit (data) {
      this.editActive = false
      this.update([this, data])
    }

  },
  components: {
    clientusersList,
    clientusersDetails,
    clientusersCreate,
    clientusersEdit
  }
}
</script>
