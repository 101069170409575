<template>
  <section class="widget" id="databasesList">
    <header class="grid">
      <h3>Banco de dados Ativos</h3>
      <span></span>
      <b-button @click="$emit('del')" class="is-danger" size="is-large" icon-left="trash"></b-button>
      <b-button @click="$emit('create')" class="__highlight" size="is-large" icon-left="plus"></b-button>
    </header>
    <div class="content">
     <b-table
        :data="databases"
        :paginated="true"
        :per-page="10"
        :row-class="(row, index) => parseWarning(row, index)"
        :selected.sync="database">
        <template slot-scope="props">
          <b-table-column field="name" label="Nome">
            {{ props.row.name}}
          </b-table-column>
          <b-table-column field="record" label="Registro">
            {{ props.row.client_record.record_name}}
          </b-table-column>
          <b-table-column field="cliente" label="Cliente">
            {{ props.row.client_record.client.slug }}
          </b-table-column>
          <b-table-column field="created_at" label="Criado em:">
            {{ parseDate(props.row.created_at) }}
          </b-table-column>
          <b-table-column field="active" label="Ativo:">
            <!-- {{ props.row.created_at }} -->
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
              <b-icon
                icon="sad-tear"
                size="is-large">
              </b-icon>
              </p>
              <p>Nenhum banco de dado cadastrado.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import dates from '../../../../mixins/dates'
export default {
  name: 'databasesList',
  mixins: [dates],
  props: {
    databases: {
      type: Array,
      default: () => [
        {
          name: 'lap-{timestamp}',
          active: 1,
          clientRecord: {
            name: 'lap_06_03_2020',
            client: {
              slug: 'lap'
            }
          }
        }
      ]
    }
  },
  data () {
    return {

    }
  },
  computed: {
    database: {
      get () {
        return this.$store.getters['databaseStore/database']
      },
      set (newVal) {
        this.$store.dispatch('databaseStore/setDatabaseIndex', newVal)
      }
    }
  },
  methods: {
    parseWarning (row, index) {
      return ''
      // if (!row.user.active) {
      //   return 'is-disabled'
      // }
    }
  }
}
</script>
