<template>
  <form @submit.prevent="$emit('edit', { s: s })" id="serversEdit">
    <header>
      <h3>Editar servidor</h3>
    </header>
    <div class="content grid">
      <b-field label="Nome" id="name">
        <b-input placeholder="Nome do servidor" v-model="s.name" required></b-input>
      </b-field>
      <b-field label="Versão" id="version">
        <b-input placeholder="Versão no servidor" v-model="s.version"></b-input>
      </b-field>
      <button class="__error" id="cancel" @click="$emit('cancel')">Cancelar</button>
      <button class="__success" id="confirm" type="submit">Salvar</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'serversEdit',
  props: ['server'],
  data () {
    return {
      s: {
        id: null,
        name: null,
        version: null
      }
    }
  },
  beforeMount () {
    this.s.id = this.deepCopy(this.server.id)
    this.s.name = this.deepCopy(this.server.name)
    this.s.version = this.deepCopy(this.server.version)
  },
  methods: {
    deepCopy (attr) {
      return JSON.parse(JSON.stringify(attr))
    }
  }
}
</script>
