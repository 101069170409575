<template>
  <form class="" id="databasesCreate" @submit.prevent="$emit('create', { database: database })">
    <header>
      <h3>Criar um novo banco de dados</h3>
    </header>
    <div class="content grid">
      <div id="client">
        <b-field label="Cliente">
          <b-select placeholder="Selecione um cliente" v-model="database.clientId" required>
            <option v-for="(client, index) in clients" :value="client.id" :key="index"> {{ client.slug }}</option>
          </b-select>
        </b-field>
      </div>
      <div id="client-record">
        <b-field label="Registro">
          <b-select placeholder="Selecione um registro" v-model="database.clientRecordId" required>
            <option v-for="(record, index) in validRecords" :value="record.id" :key="index"> {{ record.record_name }}</option>
          </b-select>
        </b-field>
      </div>
      <button class="__error" id="cancel" @click="$emit('cancel')">Cancelar</button>
      <button class="__success" id="confirm" type="submit">Criar</button>
    </div>
  </form>
</template>

<script>
import { header } from '@/config/index'
export default {
  name: 'databasesCreate',
  data () {
    return {
      validRecords: [],
      clients: [],
      database: {
        clientRecordId: null
      }
    }
  },
  watch: {
    'database.clientId' (newClientId) {
      this.getValidRecords(newClientId)
    }
  },
  computed: {
  },
  beforeMount () {
    this.$store.dispatch('clientStore/minimal', this).then(clients => {
      this.clients = clients
    })
  },
  methods: {
    getValidRecords (clientId) {
      this.validRecords = []
      this.$http.get(this.$api({
        target: `client-records/${clientId}/database-valid`
      }), {
        headers: header()
      }).then(response => {
        this.validRecords = response.data
      })
    },
    submit () {
      // this.check([this, this.database.user.email]).then(used => {
      //   this.emailStatus = {
      //     type: used ? 'is-danger' : 'is-success',
      //     message: used ? 'Email já utilizado' : 'Email válido'
      //   }
      //   return used
      // }).then(used => {
      //   if (!used) {
      //     this.$emit('create', { database: this.database })
      //   }
      //   setTimeout(() => {
      //     this.emailStatus = {
      //       emailStatus: {
      //         type: 'is-info',
      //         message: 'Email a ser verificado'
      //       }
      //     }
      //     this.database = {
      //       name: null,
      //       user: {
      //         email: null,
      //         password: null,
      //         passwordConfirmation: null
      //       }
      //     }
      //   }, 1000)
      // })
    }
  }
}
</script>
