<template>
  <main class="page" id="developers">
    <developers-list :developers="developers" @create="createActive = true"></developers-list>
    <developers-details @edit="editActive = true"></developers-details>
    <b-modal :active.sync="createActive">
      <developers-create @cancel="createActive = false" @create="create"></developers-create>
    </b-modal>
    <b-modal :active.sync="editActive">
      <developers-edit @edit="edit" @cancel="editActive = false" :developer="developer"></developers-edit>
    </b-modal>
  </main>
</template>

<script>
import developersList from './t-developers/t-developers-list'
import developersDetails from './t-developers/t-developers-details'
import developersCreate from './t-developers/t-developers-create.vue'
import developersEdit from './t-developers/t-developers-edit'
import { mapGetters, mapActions } from 'vuex'

/**
 * Developers
 * @displayName Developers Tab
 */

export default {
  name: 'developers',
  data () {
    return {
      createActive: false,
      editActive: false
    }
  },
  computed: {
    ...mapGetters('developerStore', [
      'developers',
      'developer'
    ])
  },
  beforeMount () {
    this.index(this)
  },
  methods: {
    ...mapActions('developerStore', [
      'index',
      'store',
      'update'
    ]),
    create (data) {
      this.createActive = false
      this.store([this, data])
    },
    edit (data) {
      this.editActive = false
      this.update([this, data])
    }

  },
  components: {
    developersList,
    developersDetails,
    developersCreate,
    developersEdit
  }
}
</script>
