<template>
  <section class="widget" id="connectionsDetails" v-if="connection">
    <header class="grid">
      <h3>{{ connection.name }}</h3>
      <span></span>
      <span></span>
      <b-button @click="$emit('edit')" class="__primary" size="is-large" icon-left="pen"></b-button>
    </header>
    <div class="content">
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import dates from '../../../../mixins/dates'
export default {
  name: 'clientsDetails',
  mixins: [dates],
  computed: {
    ...mapGetters('connectionStore', ['connection'])
  }
}
</script>
