<template>
  <div id="settings">
    <b-tabs>
      <b-tab-item label="Desenvolvedores" icon="user-friends">
        <t-settings-developers></t-settings-developers>
      </b-tab-item>
      <b-tab-item label="Usuários de clientes" icon="user-friends">
        <t-settings-clientusers></t-settings-clientusers>
      </b-tab-item>
      <b-tab-item label="Servidores" icon="server">
        <t-settings-servers></t-settings-servers>
      </b-tab-item>
      <b-tab-item label="Banco de Dados" icon="database">
        <t-settings-databases></t-settings-databases>
      </b-tab-item>
      <b-tab-item label="Conexões" icon="ethernet">
        <t-settings-connections></t-settings-connections>
      </b-tab-item>
      <b-tab-item label="Estatísticas" icon="tachometer-alt">
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import tSettingsDevelopers from './d-settings/t-settings-developers.vue'
import tSettingsClientusers from './d-settings/t-settings-clientusers.vue'
import tSettingsConnections from './d-settings/t-settings-connections.vue'
import tSettingsDatabases from './d-settings/t-settings-databases.vue'
import tSettingsServers from './d-settings/t-settings-servers.vue'
export default {
  name: 'settings',
  components: {
    tSettingsDevelopers,
    tSettingsClientusers,
    tSettingsConnections,
    tSettingsServers,
    tSettingsDatabases
  }
}
</script>
