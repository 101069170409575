<template>
  <form class="" id="developersCreate" @submit.prevent="submit">
    <header>
      <h3>Cadastrar desenvolvedor</h3>
    </header>
    <div class="content grid">
      <b-field label="Nome" id="name">
        <b-input placeholder="Nome do desenvolvedor" v-model="developer.name" required></b-input>
      </b-field>
      <b-field label="Email" id="email" :type="emailStatus.type" :message="emailStatus.message">
        <b-input placeholder="Email" type="email" name="email" v-model="developer.user.email" required></b-input>
      </b-field>
      <b-field label="Senha" id="password">
        <b-input type="password" placeholder="Senha" v-model="developer.user.password" password-reveal required></b-input>
      </b-field>
      <b-field :type="confirmationType.type" :message="confirmationType.message" label="Confirme a senha" id="passwordConfirmation">
        <b-input type="password" placeholder="Senha" v-model="developer.user.passwordConfirmation" password-reveal required></b-input>
      </b-field>
      <button class="__error" id="cancel" @click="$emit('cancel')">Cancelar</button>
      <button class="__success" id="confirm" type="submit">Salvar</button>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'

/**
 * Developers
 * @displayName Developers Tab Create
 */
export default {
  name: 'developersCreate',
  data () {
    return {
      emailStatus: {
        type: 'is-info',
        message: 'Email a ser verificado'
      },
      developer: {
        name: null,
        user: {
          email: null,
          password: null,
          passwordConfirmation: null
        }
      }
    }
  },
  computed: {
    confirmationType () {
      return this.developer.user.password ? (this.developer.user.password === this.developer.user.passwordConfirmation ? { type: 'is-success' } : { type: 'is-danger', message: 'As senhas devem ser iguais' }) : { type: 'is-danger', message: 'Preencha a senha' }
    }
  },
  methods: {
    ...mapActions('developerStore', [
      'check'
    ]),
    submit () {
      this.check([this, this.developer.user.email]).then(used => {
        this.emailStatus = {
          type: used ? 'is-danger' : 'is-success',
          message: used ? 'Email já utilizado' : 'Email válido'
        }
        return used
      }).then(used => {
        if (!used) {
          this.$emit('create', { developer: this.developer })
        }
        setTimeout(() => {
          this.emailStatus = {
            emailStatus: {
              type: 'is-info',
              message: 'Email a ser verificado'
            }
          }
          this.developer = {
            name: null,
            user: {
              email: null,
              password: null,
              passwordConfirmation: null
            }
          }
        }, 1000)
      })
    }
  }
}
</script>
