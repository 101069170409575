<template>
  <main class="page" id="connections">
    <connections-list :connections="connections" @create="createActive = true"></connections-list>
    <connections-details @edit="editActive = true"></connections-details>
    <b-modal :active.sync="createActive">
      <connections-create @cancel="createActive = false" @create="create"></connections-create>
    </b-modal>
    <b-modal :active.sync="loading.active">
      <circles-to-rhombuses-spinner :animation-duration="1200" :circles-num="3" :circle-size="15" :color="loading.color"/>
    </b-modal>
    <!-- <b&#45;modal :active.sync="editActive"> -->
    <!--   <connections&#45;edit @edit="edit" @cancel="editActive = false" :developer="developer"></connections&#45;edit> -->
    <!-- </b&#45;modal> -->
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CirclesToRhombusesSpinner } from 'epic-spinners'

import connectionsList from './t-connections/t-connections-list'
import connectionsDetails from './t-connections/t-connections-details'
import connectionsCreate from './t-connections/t-connections-create.vue'
// import connectionsEdit from './connections/connections-edit'
export default {
  name: 'connections',
  data () {
    return {
      createActive: false,
      editActive: false,
      loading: {
        active: false,
        color: '#fff'
      }
    }
  },
  computed: {
    ...mapGetters('connectionStore', [
      'connections'
    ])
  },
  mounted () {
    this.index(this)
  },
  methods: {
    ...mapActions('connectionStore', [
      'index',
      'store'
    ]),
    create (connection) {
      this.createActive = false
      this.loadingActive = true
      this.store([this, connection]).then(status => {
        this.loadingActive = false
      })
    }
  },
  components: {
    CirclesToRhombusesSpinner,
    connectionsList,
    connectionsDetails,
    connectionsCreate
    // connectionsEdit
  }
}
</script>
